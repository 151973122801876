"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LinkedIn from "@mui/icons-material/LinkedIn";
import GitHub from "@mui/icons-material/GitHub";
import Instagram from "@mui/icons-material/Instagram";
import Download from "@mui/icons-material/Download";
import AccountBox from "@mui/icons-material/AccountBox";
import Link from "next/link";
import WhatsApp from "@mui/icons-material/WhatsApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhoneArt from "./components/PhoneArt";
import DesktopArt from "./components/DesktopArt";
import { useTheme } from "@mui/material";
import { track } from "@vercel/analytics";

export default function HomeView() {
  const { language } = useLanguage();
  const screenTexts = texts[language].home;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const resumes = {
    ["pt-BR"]: "/files/curriculo_ezequiel_lara-PTBR.pdf",
    ["en-US"]: "/files/resume_ezequiel_lara.pdf",
  };

  return (
    <Box
      id="home"
      width="100%"
      height="100%"
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems="center"
      px={3}
      py={8}
      gap={8}
    >
      <Box display="flex" flexDirection="column" flex={1} gap={2}>
        <Box display="flex" flexDirection="column" flexWrap="wrap">
          <Typography variant={matches ? "h4" : "h2"} fontWeight={700}>
            {screenTexts.texts.title.hello}
          </Typography>
          <Typography variant={matches ? "h4" : "h2"} fontWeight={700}>
            {screenTexts.texts.title.presentation}
          </Typography>
          <Typography
            variant={matches ? "h4" : "h2"}
            color="primary"
            fontWeight={700}
          >
            {screenTexts.texts.title.role}
          </Typography>
        </Box>
        <Box>
          <Link
            href="https://www.linkedin.com/in/ezequiel-lara-/"
            target="_blank"
            aria-label="Linkedin"
          >
            <IconButton color="secondary" aria-label="Linkedin">
              <LinkedIn fontSize="large" />
            </IconButton>
          </Link>
          <Link
            href="https://github.com/EzequielZK"
            target="_blank"
            aria-label="Github"
          >
            <IconButton color="secondary" aria-label="Github">
              <GitHub fontSize="large" />
            </IconButton>
          </Link>
          <Link
            href="https://www.instagram.com/ezequielsonn/"
            target="_blank"
            aria-label="Instagram"
          >
            <IconButton color="secondary" aria-label="Instagram">
              <Instagram fontSize="large" />
            </IconButton>
          </Link>
          <Link
            href="https://w.app/d2OmIn"
            target="_blank "
            aria-label="Whatsapp"
          >
            <IconButton color="secondary" aria-label="Whatsapp">
              <WhatsApp fontSize="large" />
            </IconButton>
          </Link>
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
          <Link
            style={{ flex: 1 }}
            aria-label="Download Resume"
            rel="noopener noreferrer"
            href={resumes[language]}
            target="_blank"
            locale={false}
            onClick={() => track("Downloaded resume")}
            download
          >
            <Button
              fullWidth
              sx={{ gap: 1 }}
              variant="outlined"
              color="primary"
            >
              <Download />
              {screenTexts.buttons.downloadResumeLabel}
            </Button>
          </Link>
          <Link style={{ flex: 1 }} href="/#contact" aria-label="Hire me">
            <Button
              sx={{ gap: 1 }}
              variant="contained"
              color="primary"
              fullWidth
            >
              <AccountBox />

              {screenTexts.buttons.hireMeLabel}
            </Button>
          </Link>
        </Box>
      </Box>

      {matches ? <PhoneArt /> : <DesktopArt />}
    </Box>
  );
}
