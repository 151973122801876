'use client';

import texts from '@/lib/context/language/texts';
import useLanguage from '@/lib/hooks/useLanguage';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ScrollArrows from './components/ScrollArrows';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

export default function ProjectsView() {
  const [highlighted, setHighlight] = useState(0);
  const { language } = useLanguage();
  const screenTexts = texts[language].projects;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const addHighlight = () => {
    if (highlighted + 1 <= screenTexts.cards.length) {
      setHighlight(prevHighlight => prevHighlight + 1);
    }
  };

  const removeHighlight = () => {
    if (highlighted - 1 >= 0) {
      setHighlight(prevHighlight => prevHighlight - 1);
    }
  };

  return (
    <Box
      id='projects'
      width='100%'
      height='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      px={3}
      py={8}
    >
      <Box position='relative'>
        <Box display='flex' flexDirection='column' alignItems='center' gap={6}>
          <Typography variant='h2' fontWeight={700}>
            {screenTexts.texts.title}
          </Typography>
          <Box
            display='flex'
            gap={{ xs: 2, sm: 4 }}
            id='projects-scroll'
            maxWidth={{ xs: 300, sm: 1264 }}
            overflow={{ xs: 'auto', sm: 'hidden' }}
          >
            {!matches && (
              <ScrollArrows
                addHighlight={addHighlight}
                removeHighlight={removeHighlight}
              />
            )}

            <Box display={{ xs: 'none', sm: 'block' }} minWidth={400} />
            {screenTexts.cards.map((card, index) => {
              const prevProject = highlighted - 1;
              const nextProject = highlighted + 1;
              return (
                <Card
                  key={card.title}
                  sx={{
                    minWidth: { xs: 280, sm: 400 },
                    transform: {
                      xs: 'none',
                      sm:
                        index === highlighted
                          ? 'scale(1)'
                          : index === prevProject || index === nextProject
                          ? 'scale(0.8)'
                          : 'scale(0)',
                    },
                    transition: '0.5s',

                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderImage: theme =>
                      `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main}) 1`,
                  }}
                >
                  <CardContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='div'
                      color='primary'
                    >
                      {card.title}
                    </Typography>
                    <Box>
                      <Typography variant='body1' fontWeight={700}>
                        {card.company.title}{' '}
                        <Typography
                          component='span'
                          variant='body1'
                          color='text.secondary'
                        >
                          {card.company.company}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1' fontWeight={700}>
                        {card.description.title}
                      </Typography>
                      <Typography variant='body1' color='text.secondary'>
                        {card.description.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1' fontWeight={700}>
                        {card.whatIHaveDone.title}
                      </Typography>

                      {card.whatIHaveDone.description.map(item => (
                        <Box
                          key={item}
                          display='flex'
                          alignItems='flex-start'
                          gap={1}
                        >
                          <Box
                            width={4}
                            height={4}
                            borderRadius='50%'
                            bgcolor='text.secondary'
                            mt={1}
                          />

                          <Typography sx={{ flex: 1 }} color='text.secondary'>
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Typography variant='body1' fontWeight={700}>
                        {card.technologies.title}
                      </Typography>
                      {card.technologies.description.map(item => (
                        <Box
                          key={item}
                          display='flex'
                          alignItems='flex-start'
                          gap={1}
                        >
                          <Box
                            width={4}
                            height={4}
                            borderRadius='50%'
                            bgcolor='text.secondary'
                            mt={1}
                          />

                          <Typography sx={{ flex: 1 }} color='text.secondary'>
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
            <Box
              display={{ xs: 'none', sm: 'block' }}
              minWidth={{ xs: 300, sm: 400 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
