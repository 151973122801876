"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiImage from "@/components/muiImage";
import TsImage from "@/assets/img/TS_image.png";
import NodeImage from "@/assets/img/node_image.png";
import ReactImage from "@/assets/img/react_image.png";
import NextImage from "@/assets/img/next_image.png";
import HtmlImage from "@/assets/img/html_image.png";
import CssImage from "@/assets/img/css_image.png";
import PrismaImage from "@/assets/img/prisma_image.png";
import GitImage from "@/assets/img/git_image.png";
import TailwindImage from "@/assets/img/tailwind_image.png";
import MaterialImage from "@/assets/img/mui_image.png";
import FigmaImage from "@/assets/img/figma_image.png";
import Link from "next/link";

export default function HardSkillsView() {
  const { language } = useLanguage();
  const screenTexts = texts[language].hardSkills;

  const hardSkillList = [
    {
      alt: "Typescript website",
      image: TsImage,
      label: "TypeScript",
      href: "https://www.typescriptlang.org",
      key: "ts",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "NodeJS website",
      image: NodeImage,
      label: "NodeJS",
      key: "node",
      href: "https://nodejs.org/pt",
      imageSize: {
        maxWidth: 80,
        maxHeight: 130,
      },
    },
    {
      alt: "ReactJS website",
      image: ReactImage,
      label: "ReactJS",
      key: "react",
      href: "https://react.dev",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "ReactNative website",
      image: ReactImage,
      label: "ReactNative",
      key: "native",
      href: "https://reactnative.dev",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "NextJS website",
      image: NextImage,
      label: "NextJS",
      key: "next",
      href: "https://nextjs.org",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "HTML website",
      image: HtmlImage,
      label: "HTML",
      key: "html",
      href: "https://developer.mozilla.org/pt-BR/docs/Web/HTML",
      imageSize: {
        maxWidth: 80,
        maxHeight: 130,
      },
    },
    {
      alt: "CSS website",
      image: CssImage,
      label: "CSS",
      key: "css",
      href: "https://developer.mozilla.org/pt-BR/docs/Web/CSS",
      imageSize: {
        maxWidth: 80,
        maxHeight: 130,
      },
    },
    {
      alt: "Prisma website",
      image: PrismaImage,
      label: "PrismaORM",
      key: "prisma",
      href: "https://www.prisma.io",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "Git website",
      image: GitImage,
      label: "GIT",
      key: "git",
      href: "https://git-scm.com/download/win",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "Tailwind website",
      image: TailwindImage,
      label: "TailwindCSS",
      key: "tailwind",
      href: "https://tailwindcss.com",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "Material-UI website",
      image: MaterialImage,
      label: "Material-UI",
      key: "mui",
      href: "https://mui.com",
      imageSize: {
        maxWidth: 80,
        maxHeight: 80,
      },
    },
    {
      alt: "Figma website",
      image: FigmaImage,
      label: "Figma",
      key: "figma",
      href: "https://figma.com",
      imageSize: {
        maxWidth: 80,
        maxHeight: 130,
      },
    },
  ];

  return (
    <Box
      id="hardSkills"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={8}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
        <Typography variant="h2" textAlign="center" fontWeight={700}>
          {screenTexts.texts.title}
        </Typography>
        <Box
          display="flex"
          alignItems="baseline"
          flexWrap="wrap"
          justifyContent="center"
          gap={8}
          maxWidth={420}
        >
          {hardSkillList.map((skill) => (
            <Link key={skill.key} href={skill.href} target="_blank">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                sx={{
                  transform: "scale(0.9)",
                  ":hover": { transform: "scale(1)" },
                  transition: "0.2s",
                }}
              >
                <MuiImage
                  alt={skill.label}
                  src={skill.image}
                  sx={{ ...skill.imageSize }}
                />
                <Typography>{skill.label}</Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
