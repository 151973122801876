"use client";

import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function ProjectLoading() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="projects"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={8}
    >
      <Box width='100%'>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Skeleton variant="text" width="50%" animation="pulse" />
          <Box
            display="flex"
            justifyContent='center'
            gap={{ xs: 2, sm: 4 }}
            id="projects-scroll"
            maxWidth={{ xs: 300, sm: 1264 }}
            width='100%'
          >
            
            <Skeleton width="100%" height={800} />
            <Skeleton width="100%" height={800}  />
            <Skeleton width="100%" height={800}  />
 
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
