"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SoftSkillsView() {
  const { language } = useLanguage();
  const screenTexts = texts[language].softSkills;

  const softSkillList = [
    {
      title: screenTexts.texts.proactive.title,
      description: screenTexts.texts.proactive.description,
    },
    {
      title: screenTexts.texts.altruistic.title,
      description: screenTexts.texts.altruistic.description,
    },
    {
      title: screenTexts.texts.problemSolver.title,
      description: screenTexts.texts.problemSolver.description,
    },
    {
      title: screenTexts.texts.creativity.title,
      description: screenTexts.texts.creativity.description,
    },
    {
      title: screenTexts.texts.teamWork.title,
      description: screenTexts.texts.teamWork.description,
    },
    {
      title: screenTexts.texts.resilience.title,
      description: screenTexts.texts.resilience.description,
    },
    {
      title: screenTexts.texts.flexibility.title,
      description: screenTexts.texts.flexibility.description,
    },
    {
      title: screenTexts.texts.ethic.title,
      description: screenTexts.texts.ethic.description,
    },
  ];

  return (
    <Box
      id="softSkills"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={8}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
        <Typography variant="h2" textAlign="center" fontWeight={700}>
          {screenTexts.texts.title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={8}
          justifyContent="center"
        >
          {softSkillList.map((skill) => (
            <Box
              key={skill.title}
              width={320}
              height={160}
              display="flex"
              flexDirection="column"
              alignItems="center"
              border={2}
              borderColor="secondary.main"
              borderRadius={4}
              p={4}
              gap={2}
            >
              <Typography
                variant="body1"
                textAlign="center"
                fontWeight={700}
                color="primary"
              >
                {skill.title}
              </Typography>
              <Typography variant="body2" textAlign="center">
                {skill.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
