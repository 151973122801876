'use client';

import Box from '@mui/material/Box';
import JSONPretty from 'react-json-pretty';
import useLanguage from '@/lib/hooks/useLanguage';
import { useTheme } from '@mui/material';

export default function DesktopArt() {
  const {
    palette: { mode },
  } = useTheme();
  const { language } = useLanguage();
  return (
    <Box
      flex={1}
      sx={{ borderRadius: 8 }}
      boxShadow={theme => `0px 10px 70px -10px ${theme.palette.secondary.main}`}
      width='100%'
      display={{ xs: 'none', sm: 'block' }}
    >
      <Box
        p={2}
        display='flex'
        gap={1}
        bgcolor='white'
        sx={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
      >
        <Box borderRadius='50%' width={12} height={12} bgcolor='#fd6666' />
        <Box borderRadius='50%' width={12} height={12} bgcolor='#fb923c' />
        <Box borderRadius='50%' width={12} height={12} bgcolor='#bbf7d0' />
      </Box>
      <Box
        p={4}
        bgcolor='background.paper'
        sx={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
      >
        <JSONPretty
          themeClassName='json-pretty'
          data={{
            professionalInfo: {
              name: 'Ezequiel Lara',
              mainStacks: ['ReactJS', 'NextJS', 'NodeJS'],
              hardWorker: true,
              fastLearner: true,
              problemSolver: true,
            },
            appConfig: {
              apearence: mode,
              view: 'desktop',
              language: language,
            },
          }}
        />
      </Box>
    </Box>
  );
}
