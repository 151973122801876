'use client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import JSONPretty from 'react-json-pretty';
import SignalCellularAlt from '@mui/icons-material/SignalCellularAlt';
import BatteryStd from '@mui/icons-material/BatteryStd';
import { format } from 'date-fns';
import { MutableRefObject, useEffect, useRef } from 'react';
import useLanguage from '@/lib/hooks/useLanguage';
import { useTheme } from '@mui/material';

export default function PhoneArt() {
  const {
    palette: { mode },
  } = useTheme();
  const { language } = useLanguage();

  const clock = useRef({}) as MutableRefObject<HTMLParagraphElement>;
  let interval = setInterval(() => {
    if (clock.current) {
      clock.current.innerHTML = format(new Date(), 'HH:mm');
    }
  }, 1000);

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);
  return (
    <Box
      flex={1}
      sx={{ borderRadius: 10 }}
      boxShadow={theme => `0px 10px 70px -10px ${theme.palette.secondary.main}`}
      width='100%'
      display={{ xs: 'block', sm: 'none' }}
      border='10px solid black'
    >
      <Box
        p={1}
        display='flex'
        justifyContent='space-between'
        gap={1}
        bgcolor='rgba(0, 0, 0, 0.05)'
        sx={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }}
      >
        <Box flex={1}>
          <SignalCellularAlt />
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderRadius='50%'
          width={24}
          height={24}
          bgcolor='#000'
        >
          <Box borderRadius='50%' width={8} height={8} bgcolor='#252525' />
        </Box>
        <Box
          flex={1}
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={0.5}
        >
          <Typography
            ref={clock}
            color='text.primary'
            variant='caption'
          ></Typography>
          <BatteryStd />
        </Box>
      </Box>
      <Box
        p={{ xs: 2, sm: 4 }}
        bgcolor='background.paper'
        sx={{ borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}
      >
        <JSONPretty
          themeClassName='json-pretty'
          data={{
            professionalInfo: {
              name: 'Ezequiel Lara',
              mainStacks: ['ReactJS', 'NextJS', 'NodeJS'],
              hardWorker: true,
              fastLearner: true,
              problemSolver: true,
            },
            appConfig: {
              apearence: mode,
              view: 'mobile',
              language: language,
            },
          }}
        />
      </Box>
    </Box>
  );
}
