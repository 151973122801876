"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import emailMask from "@/lib/masks/emailMask";
import isEmail from "@/lib/validations/isEmail";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  FormGroupHandler,
  FormHandler,
  Input,
  RadioButton,
  FormButton,
} from "react-ultimate-form";
import sendEmailAction, { ContactFormData } from "./actions/sendEmail";
import { useState } from "react";
import openModal from "@/components/modal/openModal";

export default function ContactView() {
  const { language } = useLanguage();
  const screenTexts = texts[language].contact;
  const [loading, setLoading] = useState(false);

  const submit = async (data: ContactFormData) => {
    setLoading(true);
    const response = await sendEmailAction(data);
    setLoading(false);

    if (response.status === 200) {
      openModal.showAlert("success", screenTexts.form.formSuccessMessage);
    } else {
      openModal.showAlert("error", screenTexts.form.formErrorMessage);
    }
  };

  return (
    <Box
      id="contact"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={6}
        width="100%"
        height="100%"
      >
        <Typography variant="h2" textAlign="center" fontWeight={700}>
          {screenTexts.texts.title}
        </Typography>
        <Box
          display="flex"
          alignItems="stretch"
          flexDirection={{ xs: "column", sm: "row" }}
          height="100%"
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            bgcolor="background.paper"
            p={4}
          >
            <Typography variant="h3" fontWeight={700}>
              {screenTexts.texts.subtitle}
            </Typography>
          </Box>
          <FormHandler>
            <FormGroupHandler
              name="contactForm"
              onSubmit={submit}
              clearOnSubmit
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={4}
                p={4}
              >
                <Input
                  name="name"
                  label={screenTexts.form.nameInputLabel}
                  required
                />
                <Box>
                  <Typography variant="h5">
                    {screenTexts.form.iAmLabel}...
                  </Typography>
                  <RadioButton
                    orientation="row"
                    name="entity"
                    defaultValue="person"
                    options={[
                      {
                        label: screenTexts.form.peopleRadioButtonLabel,
                        value: "person",
                      },
                      {
                        label: screenTexts.form.companyRadioButtonLabel,
                        value: "company",
                      },
                    ]}
                  />
                </Box>
                <Input
                  required
                  name="email"
                  mask={emailMask}
                  validation={(value: string) =>
                    isEmail(value, screenTexts.form.emailErrorMessage)
                  }
                  label={screenTexts.form.emailInputLabel}
                />
                <Input
                  required
                  name="message"
                  multiline
                  rows={7}
                  maxLength={500}
                  label={screenTexts.form.messageInputLabel}
                />
                <FormButton color="primary" type="submit" loading={loading}>
                  {screenTexts.buttons.sendLabel}
                </FormButton>
              </Box>
            </FormGroupHandler>
          </FormHandler>
        </Box>
      </Box>
    </Box>
  );
}
