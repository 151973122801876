"use client";

import texts from "@/lib/context/language/texts";
import useLanguage from "@/lib/hooks/useLanguage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProfileImage from "@/assets/img/Eu.png";
import MuiImage from "@/components/muiImage";
import Divider from "@mui/material/Divider";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

export default function AboutView() {
  const { language } = useLanguage();
  const screenTexts = texts[language].about;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="about"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={8}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
        <Typography variant="h2" fontWeight={700}>
          {screenTexts.texts.title}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="flex-start"
          gap={8}
          width={{ xs: "100%", sm: 800 }}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={2}
            px={{ xs: 3, sm: 0 }}
          >
            <Typography variant="body1" color="primary" fontWeight={700}>
              {screenTexts.texts.background.title}
            </Typography>
            <Typography variant="body1" textAlign="justify">
              {screenTexts.texts.background.description}
            </Typography>
          </Box>
          <Divider flexItem orientation={matches ? "horizontal" : "vertical"} />
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={2}
            px={{ xs: 3, sm: 0 }}
          >
            <MuiImage
              alt="Profile Image"
              src={ProfileImage}
              sx={{
                borderRadius: 4,
                width: 280,
                height: 286,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
