"use client";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import { useMediaQuery, useTheme } from "@mui/material";

export default function AboutLoading() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      id="about"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={8}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
        <Skeleton variant="text" width='100%' animation="pulse" />
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="flex-start"
          gap={8}
          width={{ xs: "100%", sm: 800 }}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={2}
            px={{ xs: 3, sm: 0 }}
          >
            <Skeleton variant="text" animation="pulse" />
            <Skeleton variant="text" animation="pulse" />
            <Skeleton variant="text" animation="pulse" />
            <Skeleton variant="text" animation="pulse" />
            <Skeleton variant="text" animation="pulse" />
            <Skeleton variant="text" animation="pulse" />
          </Box>
          <Divider flexItem orientation={matches ? "horizontal" : "vertical"} />
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={2}
            px={{ xs: 3, sm: 0 }}
          >
            <Skeleton width={280} height={286} animation="pulse" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
