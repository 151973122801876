"use client";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export default function ScrollArrows({
  addHighlight,
  removeHighlight,
}: {
  addHighlight: () => void;
  removeHighlight: () => void;
}) {
  const [scrollLeft, setScrollLeft] = useState(0);

  const itemSize = 432;
  const itemGap = 32;
  const itemQuantity = 3;

  const totalGap = itemQuantity * itemGap;
  const totalItemSize = itemQuantity * itemSize;

  const totalSize = totalGap + totalItemSize;

  const scrollForward = () => {
    const moviesScroll = document.getElementById(`projects-scroll`);

    if (moviesScroll) {
      const canScroll = moviesScroll.scrollLeft % itemSize === 0;
      if (!canScroll) {
        return;
      }
      moviesScroll.scroll({
        left: moviesScroll.scrollLeft + itemSize,
        behavior: "smooth",
      });

      setScrollLeft(moviesScroll.scrollLeft + itemSize);
      addHighlight();
    }
  };

  const scrollBackward = () => {
    const moviesScroll = document.getElementById(`projects-scroll`);
    if (moviesScroll) {
      const canScroll = moviesScroll.scrollLeft % itemSize === 0;
      if (!canScroll) {
        return;
      }
      moviesScroll?.scroll({
        left: moviesScroll.scrollLeft - itemSize,
        behavior: "smooth",
      });
      setScrollLeft(moviesScroll.scrollLeft - itemSize);
      removeHighlight();
    }
  };

  return (
    <>
      {scrollLeft > 0 && (
        <IconButton
          onClick={scrollBackward}
          size="large"
          sx={{
            top: 400,
            left: -80,
            position: "absolute",
            p: 2,
          }}
          color="primary"
        >
          <ArrowBackIos fontSize="large" />
        </IconButton>
      )}
      {scrollLeft < totalSize && (
        <IconButton
          onClick={scrollForward}
          size="large"
          sx={{
            top: 400,
            right: -80,
            position: "absolute",
            p: 2,
          }}
          color="primary"
        >
          <ArrowForwardIos fontSize="large" />
        </IconButton>
      )}
    </>
  );
}
